import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock,
  faEye,
  faGem,
  faUmbrella,
} from '@fortawesome/pro-light-svg-icons'

const ServiceOutsourcingOffer = () => (
  <section className="service-outsourcing py-5 bg-faded">
    <div className="container">
      <div className="row mb-5">
        <div className="col-sm-12 col-md-4">
          <h4 className="seo-h2 text-sm-center text-md-right mt-0">
            Time & materials
            <br />
            modèle de prix
          </h4>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="card-group mb-5">
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faEye}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>Transparence</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                Avec les paiements mensuels il est plus facile de maintenir le contrôle financier du projet. Aucun frais cachés, aucune surprise.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faClock}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>Accessibilité</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                Programmeurs dédiés uniquement à votre projet
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faUmbrella}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>Sécurité</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                Code est toujours sécurisé grâce aux contrats de sécurité et politiques de travail conformes aux procédures de sécurité.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faGem}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>Qualité</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                Notre équipe de développeurs se compose de programmeurs expérimentés focalisés sur les objectifs commerciaux.
                </p>
              </div>
            </div>
          </div>
          <p>
            <strong>Time and Materials</strong>  est le modèle de paiement avantageux pour les deux parties. Grâce à la transparence de ce modèle, le client peut maintenir le contrôle financier et ne paie que le travail effectivement réalisé. Cette façon de comptabiliser nous permet d’optimiser notre temps de travail et financer les projets par tranches convenables.

          </p>
          <p>
          Principaux avantages <strong>Time & Materials</strong>:
          </p>
          <ul>
            <li>Contrôle qualité permanent</li>
            <li>Faibles facteurs de risque</li>
            <li>Aucun frais additionnels</li>
            <li>Soutien d’un Manager de Projet pour l’optimisation de flux de travail</li>
          </ul>
          <p>
          C’est une excellente solution pour les startups qui manquent souvent une spécifications détaillée du produit, <strong>des prototypes d’applications</strong> et d’exigences concernant la durée.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingOffer
