import React from 'react'

const ServiceOutsourcingZavenDreamTeam = () => (
  <section className="partial-cta-mvp bg-dark">
    <div className="container">
      <p className="lead text-center">
        <strong>Embauchez votre dream-team de Zaven:</strong>{' '}
        <span className="text-success">rapide, efficace et concentré sur les objectifs.</span>
      </p>
    </div>
  </section>
)

export default ServiceOutsourcingZavenDreamTeam
