import React from 'react'
import { Category, scrollToForm } from '../../contact/ContactForm'

const ServiceOutsourcingOfferCta = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <div className="lead text-left text-sm-right">
            <div className="lead text-muted">
              <h1 className="seo-span text-success">
              Externalisation du logiciel vers la Pologne
              </h1>
              <br />
              portera votre entreprise à un niveau plus élevé
            </div>
            <a
              href="#contact-partial"
              data-form-category="outsourcing"
              className="btn btn-success my-2"
              id="software-outsourcing-more-btn"
              onClick={event => {
                event.preventDefault()
                scrollToForm(Category.FreeConsultation, 0, 500)
              }}
            >
              Obtenez une consultation gratuite
            </a>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <div className="seo-p">
          Notre équipe de programmeurs nearshore est prêt à commencer à travailler sur votre application (dont à établir les exigences et la portée des travaux). Offshore externalisation permet de développer un projet sans embaucher de nouveaux employés et vous ne payez que les travaux efficacement réalisés. En externalisant vers la Pologne, vous pouvez économiser jusqu’à 30% des frais et une équipe dédiée de Zaven fera une partie intégrant de votre société.
          </div>
          <p className="text-sm text-muted">
          Notre &nbsp;
            <strong className="seo-regular">
            équipe nearshore/offshore d’externalisation
            </strong>
            &nbsp;se compose des meilleurs experts : programmeurs juniors et seniors, testeurs QA, Managers de Projet, Business Analysts, SCRUM masters et chargés de la clientèle.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingOfferCta
