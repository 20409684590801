import React from 'react'
 
const ServiceOutsourcingDevelopmentModels = () => (
 <section className="service-outsourcing py-5">
   <div className="container">
     <div className="row">
       <div className="col-md-8 offset-md-2">
         <h4 className="seo-h2 text-sm-center">Modèles de développements</h4>
         <div className="lead my-2 text-sm-center"> Il y a différents modèles de développement des applications web et mobiles de l’externalisation. Nous restons ouverts à vos propositions, nous nous adaptons à vos besoins et votre produit. La seule chose qui ne changera jamais ce sont les normes élevées que nous maintenons.
         </div>
       </div>
     </div>
 
     <div className="row my-5">
       <div className="col-sm-12 col-md-4">
         <h2 className="seo-h3 mt-2">
           <span className="text-muted display-4 float-left mr-3">NDC</span>
           Nearshore <br />
           <span className="text-success">
             development <br />
             center
           </span>
         </h2>
         <br className="clearfix" />
         <div className="seo-p">
           <strong>Une excellente solution pour les entreprises européennes</strong>. 
          Les membres de notre équipe parlent anglais et allemand. Nearshore outsourcing et la pensée occidentale ne permettent d’établir des relations d’affaires à long terme sans frontières.
         </div>
         <div className="text-sm text-muted">
           Grâce à des liaisons aériennes avantageuses vers et de la Pologne nous pouvons nous voire régulièrement avec nos partenaires de l’Europe. Maintenant vous pouvez vous concentrer sur vos affaires et nous permettre de créer pour vous des applications mobiles et web à prix attrayant. Nos équipe est un groupe d’experts techniques talentueux et passionnés qui travaillent selon les méthodologies agiles. Nous vous invitons à notre office à Wroclaw – la capitale technologique de la Pologne.
         </div>
       </div>
       <div className="col-sm-12 col-md-4">
         <h2 className="seo-h3 mt-2">
           <span className="text-muted display-4 float-left mr-3">ODC</span>
           Offshore <br />
           <span className="text-success">
             development <br />
             center
           </span>
         </h2>
         <br className="clearfix" />
         <div className="seo-p">
           <strong>
             Destiné à nos partenaires d’affaire des États-Unis, du Canada, de la Scandinavie, de l’Asie et de l’Australie
           </strong>
           &nbsp;appréciant nos experts professionnels bien formés, une gestion anglophone, des normes européennes en matière de la loi et de la sécurité ainsi que le code de qualité supérieure.
         </div>
 
         <div className="text-sm text-muted">
           Grâce à ODC nous pouvons collaborer avec les clients du monde entier. Le développement d’offshoring en Pologne est avantageux pour les partenaires étrangers. La Pologne est le membre de l’Union européenne et de l’Accord de Schengen donc le taux de change est stable et les frais du projet sont faciles à estimer. Les normes suivies au niveau mondiale, notre efficacité et rapidité nous rend un partenaire d’affaires à long terme idéal.
         </div>
       </div>
       <div className="col-sm-12 col-md-4">
         <h2 className="seo-h3 mt-2">
           <span className="text-muted display-4 float-left mr-3">DDC</span>
           Dedicated <br />
           <span className="text-success">
             development <br />
             center
           </span>
         </h2>
         <br className="clearfix" />
         <div className="seo-p">
           <strong>Idéal pour la majorité des agences IT</strong> qui nécessitent d’externaliser un amont de leur travail sans embaucher de nouveaux employés.
         </div>
         <div className="text-sm text-muted">
           Ce modèle permet d’embaucher une équipe <strong> d’experts dédiée </strong> à plein temps. Nos spécialistes connaissent également le marché et étant donné qu’ils vont travailler uniquement sur votre projet, ils vont également comprendre votre produit et savoir comment le porter à un niveau plus élevé. Il suffit d’indiquer un de vos employés qui communiquera les dispositions et recevra des rapports. Si vous êtes intéressé, envoyez-nous le brief de votre projet et nous allons vous présenter un devis concernant l’emploi d’une équipe de programmeur dédiée.
          
         </div>
       </div>
     </div>
   </div>
 </section>
)
 
export default ServiceOutsourcingDevelopmentModels
